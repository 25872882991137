import React from 'react';

export default function SignIn() {

    return (<>
<div className="row mt-5 bg-light">
    <div className="col-12 p-5">
        <h2>FOTOGRAFI I MODELI, PRIJAVITE SE!</h2>
        <h4>Volite fotografiju?</h4>
        <p>Ako ste ljubitelj fotografije ili ste profesionalni fotograf, Vaše radove možemo uvrstiti u našu ponudu.
            Kontaktirajte nas za otkup fotografija...</p>
        <p><a href={'#'} className={'btn btn-success'}>Kontaktirajte nas</a></p>
    </div>
</div>
    </>);
}
