import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {imagesApi} from '../../api';
import {RootState} from '../../store';
import {imagesSlice} from '../../store/slices/images.slice';
import {isAdmin} from '../../utilities/helper';
import Gallery from '../Gallery/Gallery';
import Clients from './Clients';
import Footer from './Footer';
import Intro from './Intro';
import SignIn from './SignIn';

export default function HomeLayout() {
  const {images} = useSelector((state: RootState) => state.images);
  const {user} = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const next = async (url: string) => {
    try {
      const {data} = await imagesApi.getLatestImages();
      dispatch(imagesSlice.actions.setImages(data));
    } catch (error) {
      console.log(error);
    }
  };
  return <>
      <Intro />
      <Gallery hasSidebar={false} isLazy={false} images={images.data} next={next} isAdmin={isAdmin(user?.role!)} relatedImage={false} />
      <Clients/>
      <SignIn />
      <Footer/>
  </>;
}
