import React from 'react';

export default function Intro() {

    return (<>
        <div className='row'>
            <div className='col-12 p-5'>
                <h1>Baza fotografija za medije i web sajtove</h1>
                <p>Ilustracije za vijesti i magazin, fotografije sa dešavanja. Fotografije za web sajtove</p>
            </div>
        </div>
        <div className='row'>
            <div className='col-3'>
                <strong>Trenutno u SERVISU »</strong>
            </div>
            <div className='col-2'>
                <p>Umjetnost</p>
                <p>Ljubav</p>
                <p>Parovi</p>
                <p>Ulična fotografija</p>
            </div>
            <div className='col-2'>
                <p>Biznis</p>
                <p>Hrana</p>
                <p>Životinje</p>
                <p>Gestikulacija</p>
            </div>
            <div className='col-2'>
                <p>Vrijeme</p>
                <p>Prognoza</p>
                <p>Ilustracije</p>
                <p>Priroda</p>
            </div>
            <div className='col-2'>
                <p>Dekoracije</p>
                <p>Modeli</p>
                <p>Dešavanja</p>
                <p>Brendovi</p>
            </div>
        </div>
    </>)
    }
