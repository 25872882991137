import React from 'react';

export default function Footer() {

    return (<div className={'row'}>
        <div className="col-12 p-5 bg-secondary">
            <p>Footer section</p>
        </div>
    </div>);

}
