import React from 'react';

export default function Clients() {

    return (<>
        <div className='row'>
            <div className='col-12 p-5'>
                <h1>Naši klijenti</h1>
            </div>
        </div>
        <div className='row'>
            <div className='col-3'>
                <a href='http://www.cafe.ba/' target='_blank'>
                    <img src='/ClientImages/cafe.jpg'  alt={'Cafe'}/></a>
            </div>
            <div className='col-3'>
                <a href='http://www.medio.rs/' target='_blank'>
                    <img src='/ClientImages/medio.jpg'  alt={'Medio'}/></a>
            </div>
            <div className='col-3'>
                <a href='http://www.haber.ba/' target='_blank'>
                    <img src='/ClientImages/haber.jpg'  alt={'Haber'}/></a>
            </div>
            <div className='col-3'>
                <a href='http://www.zenskikutak.rs/' target='_blank'>
                    <img src='/ClientImages/kutak.jpg'  alt={'Kutak'}/></a>
            </div>
        </div>
        <div className='row'>
            <div className='col-3'>
                <a href='http://www.cafe.ba/' target='_blank'>
                    <img src='/ClientImages/symple.jpg'  alt={'symple'}/></a>
            </div>
            <div className='col-3'>
                <a href='http://www.medio.rs/' target='_blank'>
                    <img src='/ClientImages/edea.jpg'  alt={'edea'}/></a>
            </div>
            <div className='col-3'>
                <a href='http://www.haber.ba/' target='_blank'>
                    <img src='/ClientImages/informatix.jpg'  alt={'informatix'}/></a>
            </div>
            <div className='col-3'>
                <a href='http://www.zenskikutak.rs/' target='_blank'>
                    <img src='/ClientImages/infobijeljina.jpg'  alt={'infobijeljina'}/></a>
            </div>
        </div>
    </>);
}
